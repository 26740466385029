import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders } from "@angular/common/http";
import { lastValueFrom } from "rxjs";
import { SessionService } from "../../session/domain/session.service";
import { language } from "ionicons/icons";
import { ApiService } from "src/core/api/domain/api.service";
import { ApiAccessService } from "src/core/api/domain/api-access.service";
import { TicketRepository } from "src/core/ticket/domain/ticket.repository";
import { Tickets } from "src/core/ticket/domain/tickets";
import { ApiGetTicketsResponse } from "src/core/ticket/infrastructure/api-get-tickets-response";
import { Ticket } from "src/core/ticket/domain/ticket";
import { TicketDetail } from "src/core/ticket/domain/ticket-detail";
import { ImageHelper } from "src/tests/core/shared/helpers/image.helper";
import { ExceptionManagerService } from "src/core/shared/exceptions/exception-manager.service";
import { ApiGetTicketDetailResponse } from "src/core/ticket/infrastructure/api-get-ticket-detail-response";
import { TicketNotFoundException } from "src/core/shared/exceptions/ticket-not-found-exception";
import { TicketAlreadyAssignedException } from "src/core/shared/exceptions/ticket-already-assigned-exception";
import { TicketNotAvailableException } from "src/core/shared/exceptions/ticket-not-available-exception";

@Injectable({
  providedIn: 'any',
})
export class HttpTicketRepository extends TicketRepository {
  constructor(
    private readonly apiService: ApiService,
    private readonly apiAccessService: ApiAccessService,
    private readonly http: HttpClient,
    private readonly sessionService: SessionService,
    private readonly exceptionManager: ExceptionManagerService,
  ) {
    super();
  }

  async delete(ticketId: string): Promise<void> {
    console.log(`Deleting ticket <${ticketId}>`);
    const mocked = false;
    if (mocked) {
      return new Promise(resolve => {
        setTimeout(() => {
          console.log(`Deleted ticket <${ticketId}>`);
          resolve();
        }, 2_000);
      });
    }

    const baseUrl = this.apiService.currentApi().urlVisitor;
    const url = `${baseUrl}/tickets/${ticketId}`;
    const apiToken = this.sessionService.getApiToken();
    const appVersion = '1.0'; // set to 1.0.0 when fixed in the API
    const apiVersion = '1.0';
    let httpHeaders = new HttpHeaders();
    httpHeaders = httpHeaders.set('X-APP-VERSION', appVersion);
    httpHeaders = httpHeaders.set('X-API-VERSION', apiVersion);
    httpHeaders = httpHeaders.set('Authorization', `Bearer ${apiToken?.token ?? '--'}`);
    const options = { headers: httpHeaders };

    return new Promise(async (resolve, reject) => {
      try {
        await lastValueFrom(this.http.delete(url, options));
        resolve();
      } catch (exception) {
        reject(this.exceptionManager.manage(exception));
      }
    });
  }

  async add(ticketId: string, reservationId: string): Promise<void> {
    console.log(`Creating new ticket with ticketId: ${ticketId}, reservationId: ${reservationId}`);
    const mocked = false;
    if (mocked) {
      return new Promise(resolve => {
        setTimeout(() => {
          console.log(`Created new ticket with ticketId: ${ticketId}, reservationId: ${reservationId}`);
          resolve();
        }, 1_500);
      });
    }

    return new Promise(async (resolve, reject) => {
      const baseUrl = this.apiService.currentApi().urlVisitor;
      const url = `${baseUrl}/tickets`;
      const apiToken = this.sessionService.getApiToken();
      const appVersion = '1.0'; // set to 1.0.0 when fixed in the API
      const apiVersion = '1.0';
      let httpHeaders = new HttpHeaders();
      httpHeaders = httpHeaders.set('X-APP-VERSION', appVersion);
      httpHeaders = httpHeaders.set('X-API-VERSION', apiVersion);
      httpHeaders = httpHeaders.set('Authorization', `Bearer ${apiToken?.token ?? '--'}`);
      const options = { headers: httpHeaders };
      const body = {
        reservationId,
        ticketId,
      };
      try {
        await lastValueFrom(this.http.post(url, body, options));
        resolve();
      } catch (exception) {
        if (exception instanceof HttpErrorResponse && exception.status == 404) {
          reject(TicketNotFoundException.fromError(exception));
          return;
        }
        if (exception instanceof HttpErrorResponse && exception.status == 409) {
          reject(TicketAlreadyAssignedException.fromError(exception));
          return;
        }
        if (exception instanceof HttpErrorResponse && exception.status == 422) {
          reject(TicketNotAvailableException.fromError(exception));
          return;
        }

        reject(this.exceptionManager.manage(exception));
      }
    });
  }

  async getTicket(ticketId: string, reservationId: string): Promise<TicketDetail> {
    const mocked = false;
    if (mocked) {
      return TicketDetail.fromPrimitives({
        id: ticketId,
        reservationId: '4664849494',
        date: '2024-11-21T10:10:00+01:00',
        productName: 'Experiencia Blue',
        email: 'rick.deckard@mail.com',
        barcode: '4664849494',
        experienceId: '2c77c078-7c4c-4a52-913c-2b9f2af0b7aa',
        qrCode: ImageHelper.base64TransparentPng(),
      });
    }

    await this.apiAccessService.renewApiAccessIfNeeded();

    const baseUrl = this.apiService.currentApi().urlVisitor;
    const url = `${baseUrl}/tickets/detail?ticketId=${ticketId}&reservationId=${reservationId}`;
    const apiToken = this.sessionService.getApiToken();
    const apiVersion = '1.0';
    const appVersion = '1.0';
    let httpHeaders = new HttpHeaders();
    httpHeaders = httpHeaders.set('Accept', 'text/plain');
    httpHeaders = httpHeaders.set('X-LANGUAGE', language);
    httpHeaders = httpHeaders.set('X-APP-VERSION', appVersion);
    httpHeaders = httpHeaders.set('X-API-VERSION', apiVersion);
    httpHeaders = httpHeaders.set('Authorization', `Bearer ${apiToken?.token ?? '--'}`);
    const options = { headers: httpHeaders };

    return new Promise(async (resolve, reject) => {
      try {
        const apiResponse = await lastValueFrom(this.http.get<ApiGetTicketDetailResponse>(url, options));
        const responseTicket =
          'ticket' in apiResponse
            ? apiResponse.ticket // Scenario with an object with `tickets`
            : apiResponse; // Scenario where directly an array

        const ticketDetail = TicketDetail.fromPrimitives({
          id: responseTicket.ticketId,
          reservationId: responseTicket.reservationId,
          date: responseTicket.eventDate,
          productName: responseTicket.productName,
          email: responseTicket.email,
          barcode: responseTicket.barcode,
          experienceId: responseTicket.experienceId,
          qrCode: responseTicket.qrCode,
        });
        resolve(ticketDetail);
      } catch (exception) {
        reject(this.exceptionManager.manage(exception));
      }
    });
  }

  async getTickets(): Promise<Tickets> {
    return new Promise(async (resolve, reject) => {
      try {
        // const apiResponse = await this.loadResponseMocked();
        const apiResponse = await this.loadResponse();
        const tickets = this.parseApiLoadResponse(apiResponse);
        resolve(tickets);
      } catch (exception) {
        if (exception instanceof HttpErrorResponse && exception.status == 404) {
          resolve(new Tickets());
          return;
        }

        reject(this.exceptionManager.manage(exception));
      }
    });
  }

  private async loadResponseMocked(): Promise<ApiGetTicketsResponse> {
    const tickets = [
      {
        ticketId: '0fabdf40-9dd0-4626-b2fa-7c32b05ff72f',
        eventDate: '2024-11-12T10:20:30+01:00',
        productName: 'Experiencia Blue',
        reservationId: '4664849494',
        status: 0,
      },
      {
        ticketId: '0fabdf40-9dd0-4626-b2fa-7c32b05ff72f',
        eventDate: '2024-11-13T12:00:00+01:00',
        experienceName: 'Experiencia Golden',
        reservationId: '2634829891',
        status: 0,
      },
    ];
    return {
      // tickets: tickets,
      tickets: [],
    };
  }

  private async loadResponse(): Promise<ApiGetTicketsResponse> {
    await this.apiAccessService.renewApiAccessIfNeeded();

    const baseUrl = this.apiService.currentApi().urlVisitor;
    const url = `${baseUrl}/visitors/tickets`;
    const apiToken = this.sessionService.getApiToken();
    const apiVersion = '1.0';
    const appVersion = '1.0';
    let httpHeaders = new HttpHeaders();
    httpHeaders = httpHeaders.set('Accept', 'text/plain');
    httpHeaders = httpHeaders.set('X-LANGUAGE', language);
    httpHeaders = httpHeaders.set('X-APP-VERSION', appVersion);
    httpHeaders = httpHeaders.set('X-API-VERSION', apiVersion);
    httpHeaders = httpHeaders.set('Authorization', `Bearer ${apiToken?.token ?? '--'}`);
    const options = {
      headers: httpHeaders,
    };

    return await lastValueFrom(this.http.get<ApiGetTicketsResponse>(url, options));
  }

  private parseApiLoadResponse(response: ApiGetTicketsResponse): Tickets {
    const responseTickets =
      'tickets' in response
        ? response.tickets // Scenario with an object with `tickets`
        : response; // Scenario where directly an array

    const tickets: Ticket[] = responseTickets.map(ticketData => {
      try {
        return Ticket.fromPrimitives({
          id: ticketData.ticketId,
          reservationId: ticketData.reservationId,
          productName: ticketData.productName,
          date: ticketData.eventDate,
          status: ticketData.status,
        });
      } catch (exception) {
        return null;
      }
    }).filter((t): t is Ticket => t !== null);

    return new Tickets(tickets)
  }

}
