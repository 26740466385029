import { Component } from '@angular/core';
import { IonApp, IonRouterOutlet, NavController, Platform } from '@ionic/angular/standalone';
import { PlatformService } from "src/core/platform/platform.service";
import { StorageService } from "src/core/shared/storage/domain/storage.service";
import { UserLoggedOutEvent } from "src/core/events/user-logged-out.event";
import { EventsService } from "src/core/events/events.service";
import { Nullable } from "src/core/shared/types/nullable.type";
import { Subscription } from "rxjs";
import { ScreenOrientation } from "@awesome-cordova-plugins/screen-orientation/ngx";
import { StatusBar } from '@awesome-cordova-plugins/status-bar/ngx';
import { EnteredVideoPlayerEvent } from "src/core/events/entered-video-player.event";
import { ExitedVideoPlayerEvent } from "src/core/events/exited-video-player.event";
import { AndroidPermissions } from '@awesome-cordova-plugins/android-permissions/ngx';
import { InitializerService } from "src/core/shared/initializer/domain/initializer.service";
import { SystemService } from "src/core/system/domain/system.service";
import { FirebaseService } from "src/core/firebase/firebase.service";

// Declare the plugin inside cordova.plugins namespace
declare namespace cordova {
  namespace plugins {
    const diagnostic: any;
  }
}

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  standalone: true,
  imports: [ IonApp, IonRouterOutlet ],
})
export class AppComponent {
  private userLoggedOut$: Nullable<Subscription> = null;
  private enteredVideoPlayer$: Nullable<Subscription> = null;
  private exitedVideoPlayer$: Nullable<Subscription> = null;

  constructor(
    private readonly androidPermissions: AndroidPermissions,
    private readonly firebaseService: FirebaseService,
    private readonly navController: NavController,
    private readonly platformService: PlatformService,
    private readonly platform: Platform,
    private readonly storageService: StorageService,
    private readonly eventsService: EventsService,
    private readonly screenOrientation: ScreenOrientation,
    private readonly initializerService: InitializerService,
    private readonly systemService: SystemService,
    private statusBar: StatusBar,
  ) {
    this.initializeApp().then().catch();
  }

  async initializeApp() {
    await this.platform.ready();
    this.printCurrentPlatformInfo();

    await this.bindEvents();
    await this.lockScreenOrientationToPortrait();
    await this.configureCordova();
    await this.storageService.ready();
    await this.resetAllStorageIfNeeded();

    // initialize all services that have storable info, to be ready synchronously
    await this.initializerService.initAll();

    await this.systemService.storeCurrentVersionToStorage();
  }

  private async resetAllStorageIfNeeded() {
    const needsToBeReset = await this.systemService.storageNeedsToBeReset();
    if (!needsToBeReset) {
      return;
    }

    await this.systemService.resetStorage();
    window.location.reload();
  }

  private async lockScreenOrientationToPortrait() {
    if (!this.platformService.isAndroid()) {
      return;
    }

    await this.screenOrientation.lock(this.screenOrientation.ORIENTATIONS.PORTRAIT);
  }

  /**
   * The platform is ready and our plugins are available.
   * Here you can do any higher level native things you might need.
   */
  private async configureCordova(): Promise<void> {
    if (!this.platform.is('cordova')) {
      return;
    }

    // Set the status bar colors
    if (this.platformService.isAndroid()) {
      // ATM, Android uses the values set in config.xml since these here are not working OK on all Android versions by now…
      // this.statusBar.backgroundColorByHexString('#ffffff');
      // this.statusBar.styleBlackTranslucent(); // Use this for Android
    } else if (this.platformService.isIos()) {
      // Override the values set in config.xml
      // Known bug is statusBar text in white on iOS 15 (iPhone 6s tested) when Dark Mode.
      this.statusBar.backgroundColorByHexString('#ffffff');
      this.statusBar.styleDefault();
    }

    await this.configureCameraPermissions(); // Added since it fixes Android AR, and it may also be needed in-app in the future to scan QR (TBC)

    // Fatal native crashes are reported to Firebase.
    // Other errors are reported to Sentry using the error handler class, since it allows better debugging.
    await this.firebaseService.init();
  }

  private async configureCameraPermissions() {
    if (this.platformService.isAndroid()) {
      this.androidPermissions.checkPermission(this.androidPermissions.PERMISSION.CAMERA).then(
        async (result) => {
          console.log('Has CAMERA permission?', result.hasPermission)
          if (!result.hasPermission) {
            await this.requestCameraPermission();
          } else {
            console.log('Already have the CAMERA permission');
          }
        },
        async (error) => {
          console.log('Error checking permission', error);
          await this.requestCameraPermission();
        },
      );
    } else if (this.platformService.isIos()) {
      cordova.plugins.diagnostic.getCameraAuthorizationStatus((status: string) => {
          console.log('Camera authorization status:', status);

          if (status === cordova.plugins.diagnostic.permissionStatus.GRANTED) {
            console.log('Camera permission already granted.');
          } else {
            console.log('Requesting camera permission...');
            cordova.plugins.diagnostic.requestCameraAuthorization(
              (status: string) => {
                if (status === cordova.plugins.diagnostic.permissionStatus.GRANTED) {
                  console.log('Camera permission granted.');
                } else {
                  console.error('Camera permission denied.');
                }
              },
              (error: any) => {
                console.error('Error requesting camera permission:', error);
              },
            );
          }
        },
        (error: any) => {
          console.error('Error getting camera authorization status:', error);
        });
    }
  }

  private async requestCameraPermission() {
    console.log('Requesting CAMERA permission');
    const hasPermission = await this.androidPermissions.requestPermission(this.androidPermissions.PERMISSION.CAMERA);
    console.log('Has acquired permission?', hasPermission);
  }

  private printCurrentPlatformInfo(): void {
    // eslint-disable-next-line no-console
    console.info(`%c Platform: ${this.platform.platforms().join(', ')}`, 'background: #222; color: #bada55');
  }

  private async bindEvents() {
    this.userLoggedOut$ = this.eventsService.userLoggedOut$.subscribe(async (event: UserLoggedOutEvent) => {
      await this.navController.navigateRoot([ '/welcome' ]);
    });

    if (this.platformService.isAndroid()) {
      this.enteredVideoPlayer$ = this.eventsService.enteredVideoPlayer$.subscribe(async (event: EnteredVideoPlayerEvent) => {
        this.screenOrientation.unlock();
      });
      this.exitedVideoPlayer$ = this.eventsService.exitedVideoPlayer$.subscribe(async (event: ExitedVideoPlayerEvent) => {
        await this.lockScreenOrientationToPortrait();
      });
    }
  }
}
