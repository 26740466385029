import { UserRepository } from "src/core/user/domain/user.repository";
import { CompletedMission, RedeemedPrize, UserInfo } from "src/core/user/domain/user-info";
import { Injectable } from "@angular/core";
import { ApiService } from "src/core/api/domain/api.service";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { SessionService } from "src/core/session/domain/session.service";
import { lastValueFrom } from "rxjs";
import { ApiGetVisitorsResponse } from "src/core/user/infrastructure/api-get-visitors-response";
import { ApiAccessService } from "src/core/api/domain/api-access.service";
import { ExceptionManagerService } from "src/core/shared/exceptions/exception-manager.service";

@Injectable({
  providedIn: 'any',
})
export class HttpUserRepository extends UserRepository {
  constructor(
    private readonly apiService: ApiService,
    private readonly apiAccessService: ApiAccessService,
    private readonly http: HttpClient,
    private readonly sessionService: SessionService,
    private readonly exceptionManager: ExceptionManagerService,
  ) {
    super();
  }

  async delete(userId: string): Promise<void> {
    await this.apiAccessService.renewApiAccessIfNeeded();

    const mocked = false;
    if (mocked) {
      const p = new Promise(resolve => {
        setTimeout(() => {
          resolve(true);
        }, 1000);
      })
      await p;
      return;
    }

    return new Promise(async (resolve, reject) => {
      try {
        const baseUrl = this.apiService.currentApi().urlVisitor;
        const url = `${baseUrl}/visitors/${userId}`;
        const apiToken = this.sessionService.getApiToken();
        const appVersion = '1.0'; // set to 1.0.0 when fixed in the API
        const apiVersion = '1.0';
        let httpHeaders = new HttpHeaders();
        httpHeaders = httpHeaders.set('X-APP-VERSION', appVersion);
        httpHeaders = httpHeaders.set('X-API-VERSION', apiVersion);
        httpHeaders = httpHeaders.set('Authorization', `Bearer ${apiToken?.token ?? '--'}`);
        const options = { headers: httpHeaders };

        await lastValueFrom(this.http.delete(url, options));
        resolve();
      } catch (exception) {
        reject(this.exceptionManager.manage(exception));
      }
    });
  }

  async updateUserData(firstName: string, lastName: string): Promise<void> {
    await this.apiAccessService.renewApiAccessIfNeeded();
    const mocked = false;
    if (mocked) {
      const p = new Promise(resolve => {
        setTimeout(() => {
          resolve(true);
        }, 1000);
      })
      await p;
      return;
    }

    return new Promise(async (resolve, reject) => {
      try {
        const baseUrl = this.apiService.currentApi().urlVisitor;
        const url = `${baseUrl}/visitors`;
        const apiToken = this.sessionService.getApiToken();
        const appVersion = '1.0'; // set to 1.0.0 when fixed in the API
        const apiVersion = '1.0';
        let httpHeaders = new HttpHeaders();
        httpHeaders = httpHeaders.set('X-APP-VERSION', appVersion);
        httpHeaders = httpHeaders.set('X-API-VERSION', apiVersion);
        httpHeaders = httpHeaders.set('Authorization', `Bearer ${apiToken?.token ?? '--'}`);
        const options = { headers: httpHeaders };
        const body = {
          firstName,
          lastName,
        };

        await lastValueFrom(this.http.put(url, body, options));
        resolve();
      } catch (exception) {
        reject(this.exceptionManager.manage(exception));
      }
    });
  }

  async getInfo(): Promise<UserInfo> {
    return new Promise(async (resolve, reject) => {
      try {
        const apiResponse = await this.loadResponse();
        resolve(this.parseApiLoadResponse(apiResponse));
      } catch (exception) {
        reject(this.exceptionManager.manage(exception));
      }
    });
  }

  private async loadResponse(): Promise<ApiGetVisitorsResponse> {
    await this.apiAccessService.renewApiAccessIfNeeded();

    const baseUrl = this.apiService.currentApi().urlVisitor;
    const url = `${baseUrl}/visitors`;
    const apiToken = this.sessionService.getApiToken();
    const appVersion = '1.0'; // set to 1.0.0 when fixed in the API
    const apiVersion = '1.0';
    let httpHeaders = new HttpHeaders();
    httpHeaders = httpHeaders.set('X-APP-VERSION', appVersion);
    httpHeaders = httpHeaders.set('X-API-VERSION', apiVersion);
    httpHeaders = httpHeaders.set('Authorization', `Bearer ${apiToken?.token ?? '--'}`);
    const options = { headers: httpHeaders };

    return await lastValueFrom(this.http.get<ApiGetVisitorsResponse>(url, options));
  }

  private mockedResponse() {
    return {
      "firstName": "Rick",
      "lastName": "Deckard",
      "countryCode": "ES",
      "languageCode": "es",
      "ticketsCount": 1,
      "completedMissions": [
        { missionId: "OzFllSkqUPHw4fbEURCortYbFJoSLZCbetOoC7GCAxsan", points: 111 },
        { missionId: "WRDi9gsGtW4C82I5ATJu4ntjmFgDI2LSb5irpTdZIPOsQL", points: 220 }, // registrate
        { missionId: "YlMf7kT1MIWYI4U85TyKIKrsroFDteDfjzfM4IGdHLJTgJ", points: 55 * 3 }, // ven acompañado
      ],
      "redeemedAwards": [
        { awardId: "5eqU3XujkCRnTlmsKOiGzFn1cPRf7lFbSJ4TOqhy0CAZSjg", points: 400 },
      ],
      "currentPoints": 400, // Math.floor(Math.random() * 1000) + 100,
    };
  }

  private parseApiLoadResponse(response: ApiGetVisitorsResponse): UserInfo {
    const completedMissions: Array<CompletedMission> = response.completedMissions.map(o => ({
      id: o.missionId,
      points: o.points,
    }));
    const redeemedPrizes: Array<RedeemedPrize> = response.redeemedAwards.map(o => ({
      id: o.awardId,
      points: o.points,
    }));

    const usedPoints = response.usedPoints ?? Math.floor(Math.random() * 100);
    const numAvailablePrizes = response.availableAwardsCount ?? Math.floor(Math.random() * 10);
    const numRedeemedPrizes = response.redeemAwardsCount ?? Math.floor(Math.random() * 10);
    const numCompletedMissions = response.missionsCompletedCount ?? Math.floor(Math.random() * 10);

    const userInfo = UserInfo.fromPrimitives({
      id: response.id,
      email: response.email,
      firstName: response.firstName,
      lastName: response.lastName,
      currentPoints: response.currentPoints,
      completedMissions,
      redeemedPrizes,
      usedPoints,
      numAvailablePrizes,
      numRedeemedPrizes,
      numCompletedMissions,
    });

    if (userInfo === null) {
      throw new Error('Cannot get user info');
    }

    return userInfo;
  }
}
